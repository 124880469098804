import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import {useSpring, animated} from 'react-spring'

const useStyles = makeStyles({
  root: {
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    alignSelf: 'center',
    textAlign: 'center',
  },
  title: {
    fontSize: 80,
    fontFamily: [
        'Creepster',
        'Cursive',
      ].join(','),
  },
  pos: {
    marginBottom: 5,
  },
});

export default function OutlinedCard() {
  const classes = useStyles();

  const props = useSpring({
    config: { duration: 2000 },
    opacity: 1,
    from: { opacity: 0 },
  })

  return (
    <animated.div style={props}>
    <Card className={classes.root} style={{backgroundColor: 'black'}}>
      <CardContent>
        <Typography className={classes.title} gutterBottom>
          Bio
        </Typography>
        <Typography variant="body2" component="p">
        Suncrusher is a heavy band formed in the city of Chicago, IL in 2018. Influenced by the various forms of extreme metal including melodic death metal, metalcore and deathcore. 
        Suncrusher seeks to expand on the music styles they love. In 2022 they released the EP "Worthless Society", produced by Pablo Viveros which set forth exploration into lower tuning and heavier subject matter. 
        After some lineup changes, Suncrusher is actively performing in the midwest and writing new music.
        </Typography>
      </CardContent>
    </Card>
    </animated.div>
  );
}